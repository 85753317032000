.Table {
  min-width: 280px;
  width: 80%;
  max-width: 600px;
  border-collapse: collapse;
}

.Table--container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
}

.Table--heading {
  background-color: hsl(217, 50%, 14%);
  text-align: end;
}

.Table--heading th {
  padding: 1rem;
}

.Table--heading-name {
  text-align: start;
}

.Table--row {
  background-color: hsl(214, 56%, 16%);
  text-align: end;
  border: 1px solid hsl(222, 14%, 42%);
  border-right: none;
  border-left: none;
}
.Table--row:hover {
  background-color: hsl(211, 62%, 19%);
}

.Table--row td.bullish,
.Table--row td.bearish {
  font-weight: 500;
}

.Table--row td.bullish {
  color: hsl(171, 100%, 41%);
}
.Table--row td.bearish {
  color: hsl(352, 100%, 78%);
}

.Table--row td {
  padding: 1rem;
}

.Table--currency {
  text-align: start;
  font-weight: 600;
}

.Table--pagination-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Table--pagination-button {
  cursor: pointer;
  border: 0;
  padding: 8px;
  background: none;
  color: var(--white);
  border-radius: 12px;
  min-width: 60px;
}
.Table--pagination-button:hover {
  background-color: hsl(222, 23%, 83%);
  color: hsl(0, 0%, 13%);
}
.Table--pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.Table--pagination-text {
  margin: 0;
}

.Table--pagination-text strong {
  font-weight: 600;
}
