.Main-container {
  min-height: 100vh;
  padding: 20px 0 40px;
  display: flex;
  flex-direction: column;
}

.LoadingText {
  text-align: center;
  font-size: 3rem;
  margin: auto 0;
}

.ApiToggle {
  cursor: pointer;
  margin: 0 10% 32px 0;
  background: none;
  border: 1px solid var(--white);
  border-radius: 12px;
  color: var(--white);
  font-weight: 600;
  padding: 8px 16px;
  align-self: flex-end;
}
.ApiToggle:hover {
  background-color: var(--white);
  color: hsl(0, 0%, 0%);
}

.NoResultsText {
  text-align: center;
  font-size: 2rem;
  margin-top: 40%;
}
