.SearchBar--container {
  width: max-content;
  margin: 0 auto 32px;
}

.SearchBar {
  padding: 10px 16px;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  background-color: hsl(211, 62%, 19%);
  color: var(--white);
}
.SearchBar:hover {
  box-shadow: 0 2px 6px hsla(225, 68%, 31%, 0.28);
}

.SearchBar::placeholder {
  color: var(--white);
  opacity: 0.5;
}

.SearchBar::-webkit-search-cancel-button {
  -webkit-appearance: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
  background-color: var(--white);
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}
.SearchBar::-webkit-search-cancel-button:hover {
  opacity: 0.8;
}

.SearchBar--text {
  text-align: center;
}
